import { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import './index.scss';

function Video(props, ref) {
  let { size, src, styleSetting, poster } = props;

  const videoRef = useRef();
  const [isPlay, setIsPlay] = useState(false);

  poster = poster || src + (src.indexOf('?') > -1 ? '&' : '?') + 'x-oss-process=video/snapshot,t_1,f_jpg,w_0,h_0,m_fast';

  function play() {
    videoRef.current.play();
    if (videoRef.current.requestFullscreen) {
      // 最新标准
      videoRef.current.requestFullscreen();
    } else if (videoRef.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen();
    } else {
      // iOS进入全屏
      setTimeout(() => {
        videoRef.current.webkitEnterFullscreen();

        // 针对iOS监听不到webkitfullscreenchange事件做的兼容，感知退出全屏
        let timer = setInterval(() => {
          if (!videoRef.current.webkitDisplayingFullscreen) {
            // 退出了全屏
            clearInterval(timer);
          }
        }, 1000);
      }, 100)
    }
  }



  function pause() {
    videoRef.current.pause();
  }

  function setPlayState() {
    setIsPlay(!isPlay);
  }

  useImperativeHandle(ref, () => ({
    play,
    pause
  }))

  return (
    <div className='book-content-video-container'>

      {!isPlay && (
        <div className='cover'>
          <div className='size-tip' onClick={play}>
            <i className='play-icon'
            style={{
              backgroundImage:
                `url('${styleSetting.playIcon
                ||
                require('./image/video-play.png')}')`
            }}
            /> 
          </div> 
        </div>
      )}

      <video
        ref={videoRef}
        onPause={setPlayState}
        onPlay={setPlayState}
        controls={isPlay}
        src={src}
        poster={poster}
        webkit-playsinline='true'
        playsInline
        controlsList='nodownload'
        // fullser
        className='video'
      ></video>
    </div>
  )
}

export default forwardRef(Video);