import { useEffect, useRef, useState } from 'react';
import './index.scss';
import { useParams, useNavigate } from 'react-router';
import { getBookContent, getStyle } from '../../common/api';
import { Toast, Image, ImageViewer } from 'antd-mobile';
import Video from './component/video';
import Audio from './component/audio';

const Resource = () => {
  const [list, setList] = useState([]);
  const [title, setTitle] = useState('');
  const [isVideoPlay, setIsVideoPlay] = useState(false);
  const [isAudioPlay, setIsAudioPlay] = useState(false);
  const [styleSetting, setStyleSetting] = useState({});
  const [loading, setLoading] = useState(true)
  const { code } = useParams();
  const navigateTo = useNavigate();

  useEffect(() => {
    getBookContent(code).then((data) => {
      if (data.code === 200 && data.data) {
        document.title = data.data.name;
        const { resources = [], name = '' } = data.data;
        setList(resources);
        setTitle(name);
        // 如果styleSettingId为null就默认风格
        if (data.data.book.styleSettingId) {
          _getStyle(data.data.book.styleSettingId)
        }
      } else if (data.code === 404) {
        navigateTo('/404.html');
      } else if (data.code === 401) {
        navigateTo(`/login.html`)
      } else {
        Toast.show({
          content: data.msg,
        })
      }
    }).then(() => {
      setTimeout(() => {
        setLoading(false)
      }, 100);
    }).catch((err) => {
      Toast.show({
        content: err.msg,
      })
    })
  }, []);

  function _getStyle(styleSettingId) {
    getStyle(styleSettingId).then((data) => {
      console.log(data.data)
      if (data.code === 200 && data.data) {
        setStyleSetting(data.data.value)
      }
    })
  }

  function onPictureClick(visible) {
    setIsVideoPlay(!visible);
    setIsAudioPlay(!visible);
  }

  if (loading) return <div></div>;
  return (
    <div className='book-content-list-page'
      style={{
        backgroundImage:
          `url('${styleSetting.bookContentBackground
          ||
          require('./image/contetn-bg.png')}')`
      }}
    >

      {/*{title && (*/}
      {/*  <div className="title">《<div className='title-content'>{title}</div>》</div>*/}
      {/*)}*/}
      {/*<img src={require('./image/contetn-bg.png')} className='bg'></img>*/}
      {list.map((item, index) => {
        return (
          <ListItem
            title={item.name}
            type={item.type}
            size={item.size}
            key={index}
            src={item.url}
            poster={item.poster}
            isVideoPlay={isVideoPlay}
            isAudioPlay={isAudioPlay}
            onPictureClick={onPictureClick}
            styleSetting={styleSetting}
          ></ListItem>);
      })}

      {list.length === 0 && (
        <p className='no-data'>暂无数据</p>
      )}
    </div>
  )
}

const ListItem = (props) => {
  const { title, src, poster, type, size, onPictureClick, isVideoPlay, isAudioPlay, styleSetting } = props;

  const videoRef = useRef();
  const audioRef = useRef();

  const [visible, setVisible] = useState(false);

  const sizeNumber = (size / 1024 / 1024).toFixed(1);
  const isVideo = /^video\//i.test(type);
  const isPicture = /^image\//i.test(type);
  const isAudio = /^audio\//i.test(type);

  useEffect(() => {
    // 为了控制视频统一暂停
    // 当触发查看图片的时候，所有视频都暂停
    if (isVideo && !isVideoPlay) {
      videoRef.current.pause();
    }

    if (isAudio && !isAudioPlay) {
      audioRef.current.pause();
    }
  }, [isVideoPlay, isAudioPlay])

  function setCheckState() {
    if (typeof onPictureClick === 'function') {
      onPictureClick(!visible);
    }
    setVisible(!visible);
  }

  return (
    <div className='book-content-list-item'>
      <div className='item-title'
        style={{
          backgroundImage:
            `url('${styleSetting.bookContentNameBackground
            ||
            require('./image/contetn-title-bg.png')}')`,
          color: styleSetting.fontColor ? `#${styleSetting.fontColor}` : '#ffffff'
        }}>
        <div>{title.slice(0, 10)}</div>
        <div>{title.slice(10, 20)}</div>
      </div>

      {isVideo && (
        <Video ref={videoRef} src={src} poster={poster} styleSetting={styleSetting} isVideoPlay={isVideoPlay} size={sizeNumber}></Video>
      )}

      {isPicture && (
        <>
          <Image
            fit='contain'
            src={src}
            className='image'
            onClick={setCheckState}
          ></Image>

          <ImageViewer
            image={src}
            visible={visible}
            onClose={setCheckState}
          />
        </>
      )}

      {isAudio && (
        <Audio src={src} ref={audioRef} isAudioPlay={isAudioPlay} styleSetting={styleSetting}></Audio>
      )}
    </div>
  )
}


export default Resource;