import { useEffect, useRef, useState } from 'react';
import './index.scss';
import { useParams, useNavigate } from 'react-router';
import { getBookContent } from '../../common/api';
import { Toast, Image, ImageViewer } from 'antd-mobile';
import Video from './component/video';
import localStorage, { cookies } from '../../common/local-storage';

const Resource = () => {
  const [list, setList] = useState([]);
  const [title, setTitle] = useState('');
  const [isVideoPlay, setIsVideoPlay] = useState(false);
  const { code } = useParams();
  const navigateTo = useNavigate();
  const accessToken = cookies.get('accessToken') || localStorage.get('TOKEN')
  localStorage.set('TOKEN', accessToken)

  useEffect(() => {
    _getBookContent()

  }, []);

  const _getBookContent = () => {
    getBookContent(code).then((data) => {
      if (data.code === 200 && data.data) {
        const { resources = [], name = '', isOpen } = data.data;
        if (isOpen) {
          setList(resources);
          setTitle(name);
        } else {
          if (accessToken) {
            setList(resources);
            setTitle(name);
          } else {
            navigateTo(`/login.html?openId=${cookies.get('openId')}`)
          }
        }
      } else if (data.code === 404) {
        navigateTo('/404.html');
      } else if (data.code === 401) {
        navigateTo(`/login.html`)
      } else {
        Toast.show({
          content: data.msg,
        })
      }
    }).catch((err) => {
      Toast.show({
        content: err.msg,
      })
    })
  }

  function onPictureClick(visible) {
    setIsVideoPlay(!visible);
  }

  return (
    <div className='resource-list-page'>

      {title && (
        <div className='title'>《{title}》</div>
      )}

      {list.map((item, index) => {
        return (
          <ListItem
            title={item.name}
            type={item.type}
            size={item.size}
            key={index}
            src={item.url}
            poster={item.poster}
            isVideoPlay={isVideoPlay}
            onPictureClick={onPictureClick}
          ></ListItem>);
      })}

      {list.length === 0 && (
        <p className='no-data'>暂无数据</p>
      )}
    </div>
  )
}

const ListItem = (props) => {
  const { title, src, poster, type, size, onPictureClick, isVideoPlay } = props;

  const videoRef = useRef();

  const [visible, setVisible] = useState(false);

  const sizeNumber = (size / 1024 / 1024).toFixed(1);
  const isVideo = /^video\//i.test(type);
  const isPicture = /^image\//i.test(type);
  const isAudio = /^audio\//i.test(type);

  useEffect(() => {
    // 为了控制视频统一暂停
    // 当触发查看图片的时候，所有视频都暂停
    if (isVideo && !isVideoPlay) {
      videoRef.current.pause();
    }
  }, [isVideoPlay])

  function setCheckState() {
    if (typeof onPictureClick === 'function') {
      onPictureClick(!visible);
    }
    setVisible(!visible);
  }

  return (
    <div className='resource-list-item'>
      <div className='item-title'>
        {title}
      </div>

      {isVideo && (
        <Video ref={videoRef} src={src} poster={poster} isVideoPlay={isVideoPlay} size={sizeNumber}></Video>
      )}

      {isPicture && (
        <>
          <Image
            fit='contain'
            src={src}
            onClick={setCheckState}
          ></Image>

          <ImageViewer
            image={src}
            visible={visible}
            onClose={setCheckState}
          />
        </>
      )}

      {isAudio && (
        <audio
          controls
          preload='auto'
          src={src}
        ></audio>
      )}
    </div>
  )
}


export default Resource;